
function getEnvUrl (env){
  const urls = {
    'production': '/api',
    'pre': '/api',
    'local': 'http://localhost:3000'
  }
  return urls[env] ?? urls.local
}

function getMap (env){
  // webMap: {
  // id: 'ec64acc3794841779387f5ab423eb17c', // 3 niveles de capas
  //id: '579e55dd4e8b4a4d9ffb1c997e9894fd', // (antiguo) 4 niveles de capas (PRE)
  // 653e85da73b543909849f38ca0b71f04 Nuevo mapa pre
  // id: '68b4788b544e46e48594c3d520d964b5', // sin capas kml (privado y ligero)
  // id: 'c441c5557ab145dca4ec74a4ea2f5075', // Producción
  // id: '4814d5d4bcab402fbe23fe05727cebb4', // old
  // id: '92bef47bd0a448999b4740d1fe52f676' // sin kml (otro)
  // id: '0cebd416df9e400490ff78fedd13f255' // mapa sin capas (solo emergencias y distritos) MOVIL
  // portal: 'https://www.arcgis.com' 
  // },
  const maps = {
    'pre': {
      id: '0c2578c3d03c4076ba840af2f2f1ff83', // MAPA PRE
      mobile: '9ee4530ec0784e8495e4f28819a25bd2',
      portal: 'https://www.arcgis.com'
    },
    'production': {
      id: 'af137fcb23d14d5390689aafbd5bd259', // MAPA EXP
      mobile: '0de34de8ecdc4e2a9b8a629f4b2a0535',
      portal: 'https://www.arcgis.com'
    },
    'local': {
      id: '0c2578c3d03c4076ba840af2f2f1ff83', // MAPA DEV
      mobile: '9ee4530ec0784e8495e4f28819a25bd2',
      portal: 'https://www.arcgis.com'
    }
  }
  return maps[env] ?? maps.local
}

export const baseUrl = getEnvUrl(process.env.NODE_ENV)
export const baseMap = getMap(process.env.NODE_ENV)